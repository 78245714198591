<template>
  <div class="container">
    <div class="row">
      <div class="col-md-10 mx-auto">
        <div class="card border-0 shadow-none bg-light" v-if="!isAuthenticated">
          <div class="card-body">
            <h1 class="mb-4">Welcome to the IFAC Affiliates portal</h1>
            <p class="">
              This portal gives you access to IFAC services dedicated to individuals who signed up as IFAC Affiliates.
              The services include the possibility to share and consult information about other Affiliates.
              It also gives access to more advanced features such as:
              <ul>
                <li>
                  Receiving the <a
                    href="https://www.ifac-control.org/publications/newsletter_archives"
                    class="text-decoration-none"
                    target="_blank"
                  >IFAC Newsletter</a>.

                </li>
                <li>
                  Receiving alerts about the IFAC Conferences in your
                  <a
                    class="text-decoration-none"
                    href="https://www.ifac-control.org/areas"
                    target="_blank"
                  >
                    field/s of interest</a>.
                </li>
                <li>
                  Benefit for reduced registration fees at IFAC Conferences. Conferences are typically 10€ (or the local equivalent)
                  less expensive for IFAC Affiliates, than for non-affiliates.
                </li>
                <li>
                  Participating in
                  <a href="https://tc.ifac-control.org/" class="text-decoration-none" target="_blank">
                  IFAC Technical Activities</a>.
                </li>
                <li>
                  Organizing
                  <a
                    href="https://www.ifac-control.org/conferences/@@conference_view"
                    class="text-decoration-none" target="_blank"
                  >IFAC Conferences</a>.
                </li>
                <li>
                  Participating in <a
                    href="https://www.ifac-control.org/publications/journals"
                    class="text-decoration-none" target="_blank"
                  >IFAC Journals</a>.
                </li>
                <li>
                   Applying to <a
                   class="text-decoration-none" href="https://www.ifac-control.org/awards" target="_blank">
                    IFAC Awards</a>.
                </li>
                <li>
                  Applying to the <a
                    class="text-decoration-none" target="_blank"
                    href=" https://www.ifac-control.org/areas/activity-fund">
                    IFAC Activity fund</a>.
                </li>
                <li>
                  And more to come in the future.
                </li>
              </ul>
            </p>
            <p>
              Moreover, being an IFAC Affiliate is free of charge. Any individual who is interested in
              Control Engineering should sign up!
            </p>
            <p>How do I connect with IFAC?</p>
            <ul>
              <li>If you are already an IFAC Affiliate,
                <a href="#" @click.prevent="login">Connect to your personal account</a> and access all IFAC restricted data.</li>
              <li>If you are not yet an IFAC Affiliate,
                <a href="#" @click.prevent="register">Sign up</a> for free to become one, and meanwhile,
                 you may also browse the Web public data.</li>
            </ul>
            <p>
              For any additional information, or if you require any assistance, please contact the IFAC Secretariat at
              <a class="text-decoration-none" href="mailto:secretariat@ifac-control.org">secretariat@ifac-control.org</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
  methods: {
    login() {
      this.$store.dispatch('auth/login');
    },
    register() {
      this.$store.dispatch('auth/register');
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push('/dashboard');
    }
  },
};
</script>
